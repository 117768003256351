import React from 'react'
import LocalizedLink from "../components/localizedLink"
import useTranslations from "../components/useTranslations"
import { graphql, useStaticQuery, Link } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image'
import HeroVideo from '../videos/PartyCover.mp4'
import Button from '../components/button'
import Helmet from 'react-helmet'

const Party = ({ data }) => {

    // const pluginImage = getImage(data.heroImage)
    console.log("result query");
    console.log(data.comingEvents);

    const { 
        party_title, 
        party_subtitle, 
        party_upcoming_event, 
        party_online, 
        party_title_fantasy, 
        party_text_fantasy,
        dont_miss_out,
        upcoming_events,
        past_events,
        our_world_famous_events,
        we_challenge,
        each_lifestyle_fever_party,
        parties_in_asia

    } = useTranslations()

    return (
        <div id='innerPageWrapper'>
            <Helmet title='Lifestyle Fever | The Most Exculive, Elegant and Upscaled Erotic Paries in Asia' />
            <div className='bg-[#111] p-12'>
                <h1 className="text-3xl text-skin-base text-center">{party_title}</h1>
                <h3 className='text-skin-primary text-center'>{party_subtitle}</h3>
            </div>

            {/* Next big event */}
            <div className="relative overflow-hidden">
                <div className="absolute inset-0 h-[500px]">
                    <StaticImage 
                        src="../images/event/LSF2022_hero.png" 
                        alt=""
                        className="w-full h-full object-bottom object-cover"
                    />
                </div>
                <div className="relative bg-black bg-opacity-50 p-6 h-[500px] flex items-center">
                    <div className="container font-thin text-4xl text-center">
                        <StaticImage 
                            src='../images/event/2022event_logo.png'
                            alt="LSF 2022 Annual Party"
                            height={300}
                            layout='constrained'
                        />
                        
                        <p className='mt-12'>
                            <Link to='/event/2022-taipei'>
                                <Button text={dont_miss_out}></Button>
                            </Link>
                        </p>
                    </div>
                </div>
            </div>

            {/* Upcoming events */}
            <div className="mx-auto md:container text-center pb-12">
                <h1 className="uppercase py-14">{upcoming_events}</h1>
                <div className='grid grid-flow-row md:grid-cols-3 gap-6 container'>
                        {/* cards */}
                        {
                            data.comingEvents.edges.map((node) => (
                                <LocalizedLink to={`/${node.node.parent.relativeDirectory}`} key={ node.node.id } className="drop-shadow-md">
                                    <div className='flex flex-col items-center drop-shadow-md'>
                                        <div className='card_events'>
                                            <div className="bg-[#111] rounded-t-3xl">
                                                <GatsbyImage 
                                                    image={ getImage(node.node.frontmatter.thumb_image)}
                                                    alt={node.node.slug}
                                                    className='rounded-t-3xl'
                                                />
                                            </div>
                                            <div className='flex flex-row bg-black rounded-b-3xl px-2 py-3'>

                                                <div className='text-xs font-normal p-3 text-skin-primary'>
                                                    <h2>{ node.node.frontmatter.date}</h2>
                                                </div>
                                        
                                                <div className='flex-grow text-xs text-left'>
                                                    <ul>
                                                        <li>{ node.node.frontmatter.title }</li>
                                                        <li>{party_online}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </LocalizedLink>
                            ))
                        }
                </div>
            </div>

            <div className='p-12 text-center bg-black'>
                <div className='container'>
                    <h1 className='font-medium mb-6'>{party_title_fantasy}</h1>
                    <p>
                        {our_world_famous_events}
                    </p>
                </div>
            </div>
            <div className="relative overflow-hidden">
                 <video loop autoPlay muted width="100%">
                    <source 
                        src={HeroVideo}
                        type="video/mp4"
                    />
                    Your browser does not suppor thie video tag.
                </video>
            </div>

            <div className='bg-[#111] p-12'>
                <h1 className="text-3xl text-skin-base text-center">{parties_in_asia}</h1>
                <h3 className='text-skin-primary text-center'>{dont_miss_out}</h3>
            </div>

            {/* Party girl section */}
            <div className="hidden md:block relative overflow-hidden h-[680px]">
                <div className="absolute inset-0 flex justify-end bg-black">
                    <StaticImage 
                        src="../images/site/626_party_girl.png"
                        alt="Swinger Lifestyle Party Girls"
                        layout="constrained"
                        height={680}
                        quality="100"
                    />
                </div>
                <div className="relative p-6 h-full flex items-center">
                    <div className="container flex flex-row">
                        <div className='flex-1'>
                            <h6 className='text-5xl mb-6 text-skin-primary'>
                                Lifestyle Party
                            </h6>
                            <p>
                                {we_challenge}
                            </p>
                            <p>
                                {each_lifestyle_fever_party}
                            </p>
                        </div>
                        <div className="flex-1">
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Party girl section mobile */}
            <div className='md:hidden bg-black'>
                <div>
                    <StaticImage 
                        src='../images/site/626_party_girl_mobile.png'
                        alt='Swinger Lifestyle Party Girl for mobile'
                        layout="constrained"
                    />
                </div>
                <div className='p-6 text-center mt-3'>
                    <h6 className='text-5xl mb-6 text-skin-primary'>
                        Lifestyle Party
                    </h6>
                    <p>
                        {we_challenge}
                    </p>
                    <p>
                        {each_lifestyle_fever_party}
                    </p>
                </div>
            </div>

            

            {/* Past events */}
            <div className="mx-auto md:container text-center">
                <h1 className="uppercase py-14">{past_events}</h1>
                <div className='grid grid-flow-row md:grid-cols-3 gap-6 lg:max-w-3xl place-content-center container'>
                        {/* cards */}
                        {
                            data.pastEvents.edges.map((node) => (
                                <LocalizedLink to={`/${node.node.parent.relativeDirectory}`} key={ node.node.id } className="drop-shadow-md">
                                    <div className='flex flex-col items-center drop-shadow-md'>
                                        <div className='card_events'>
                                            <div className="bg-[#111] rounded-t-3xl">
                                                <GatsbyImage 
                                                    image={ getImage(node.node.frontmatter.thumb_image)}
                                                    alt={node.node.slug}
                                                    className='rounded-t-3xl'
                                                />
                                            </div>
                                            <div className='flex flex-row bg-black rounded-b-3xl py-3 px-6'>
                                                <div className='flex-grow text-xs text-left'>
                                                    <ul>
                                                        <li>{ node.node.frontmatter.title }</li>
                                                        <li>Online</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </LocalizedLink>
                            ))
                        }
                </div>
            </div>
        </div>
    )

}

export const queryPrimary = graphql`
        query ($locale: String!){
            site {
                siteMetadata {
                title
                siteUrl
                }
            }
            comingEvents: allMdx(
                sort: { fields: frontmatter___postDate, order: ASC }
                filter: {
                frontmatter: { type: { eq: "Party" }, status: { eq: "Upcoming" } },
                fields: { locale: { eq: $locale } }
                }
            ) {
                edges {
                    node {
                        id
                        slug
                        frontmatter {
                            date
                            title
                            type
                            postDate(formatString: "MM D, YY")
                            thumb_image_alt
                            thumb_image {
                                childImageSharp {
                                    gatsbyImageData (
                                        width: 300
                                    )
                                }
                            }
                        }
                        fields {
                            locale
                        }
                        parent {
                            ... on File {
                            relativeDirectory
                            }
                        }
                    }
                }
                nodes {
                frontmatter {
                    date
                    title
                    type
                    postDate(formatString: "MM D, YY")
                    thumb_image_alt
                    thumb_image {
                        childImageSharp {
                            gatsbyImageData (
                                width: 300
                            )
                        }
                    }
                }
                id
                slug
                }
            }
            pastEvents: allMdx(
                sort: { fields: frontmatter___postDate, order: ASC }
                filter: {
                frontmatter: { type: { eq: "Party" }, status: { eq: "Past" } }
                fields: { locale: { eq: $locale } }
                }
            ) {
                edges {
                    node {
                        id
                        slug
                        frontmatter {
                            date
                            title
                            type
                            postDate(formatString: "MM D, YY")
                            thumb_image_alt
                            thumb_image {
                                childImageSharp {
                                    gatsbyImageData (
                                        width: 300
                                    )
                                }
                            }
                        }
                        fields {
                            locale
                        }
                        parent {
                            ... on File {
                            relativeDirectory
                            }
                        }
                    }
                }
                nodes {
                frontmatter {
                    date
                    title
                    type
                    postDate(formatString: "MMMM D, YYYY")
                    thumb_image_alt
                    thumb_image {
                        childImageSharp {
                            gatsbyImageData(
                                transformOptions: {fit: COVER}
                            )
                        }
                    }
                }
                id
                slug
                }
            }
            heroImage: file(name: {eq: "LSF2022_hero"}) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 60
                        webpOptions: {quality: 50}
                    )
                }
            }
        }
`

export default Party